body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px;
  color: #000;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background-color: #F3EFEF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: inherit;   
  text-decoration: none;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.static-underline {
  text-decoration: underline;
}

.hover-underline:hover {
  text-decoration: underline;
}
