.wrapper {
	padding: 8%;
}

#hello {
	font-family: 'Poppins', sans-serif;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 90px;
	color: #000;
	margin-top:5%;
    animation: fadeInAnimation ease .8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
     }
}

#contact {
    text-decoration: none;
}


@media (max-width: 767px) {
    #hello {
    font-size: 30px;
    line-height: 40px;
    margin-top:5%;
    }       
}


